import { render, staticRenderFns } from "./update-name.vue?vue&type=template&id=808eb59c&scoped=true&"
import script from "./update-name.vue?vue&type=script&lang=js&"
export * from "./update-name.vue?vue&type=script&lang=js&"
import style0 from "./update-name.vue?vue&type=style&index=0&id=808eb59c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "808eb59c",
  null
  
)

export default component.exports